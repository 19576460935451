<template>
  <!-- <div class="login"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  <div class="login-box">
    <div class="title">请绑定账号信息</div>
    <input
      type="text"
      id="user"
      v-model="user"
      placeholder="请输入账号用户名"
      autocomplete="off"
    />
    <input
      type="text"
      v-model="phone"
      id="phone"
      placeholder="手机号"
      autocomplete="off"
    />
    <div class="codebox">
      <input
        type="text"
        id="code"
        v-model="code"
        placeholder="验证码"
        autocomplete="off"
      />
      <div class="code" @click="getCode(0)">获取验证码</div>
    </div>
    <input
      type="password"
      v-model="pwd"
      id="pwd"
      placeholder="登录密码"
      autocomplete="off"
    />
    <input
      type="password"
      id="repwd"
      v-model="repwd"
      placeholder="确认登录密码"
      autocomplete="off"
    />
    <div class="choose">
      <div class="right">
        <span>已有账号</span> ,
        <router-link to="/login/"
          ><span style="color: orange">马上登录</span></router-link
        >
      </div>
    </div>
    <div class="login-btn" @click="isRegister">
      <img src="../../img/登录_slices/矩形 2 拷贝 2.png" alt="" />
      <span>绑定账号</span>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import * as util from "@/unit/network";
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import $ from "jquery";
export default {
  name: "Login",
  data() {
    return {
      user: "",
      phone: "",
      pwd: "",
      code: "", //验证码
      repwd: "",
      i: 0,
    };
  },
  mounted() {
    // console.log(localStorage.getItem(JSON.parse("wechat")));
    let wechat = localStorage.getItem("wechat");
    let arr = JSON.parse(wechat);
    this.user = arr.nickname;
  },
  methods: {
    isRegister() {
      // this.$router.push("/login/resReg");
      util.post(
        "/bind",
        {
          username: this.user,
          phone: this.phone,
          code: this.code,
          password: this.repwd,
        },
        (res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.$router.push("/login/logins");
          }
        }
      );
    },
    getCode(i) {
      if (i == 0) {
        util.post("/sendcode", { phone: this.phone }, (res) => {
          console.log(res);
          if (res.code == 1) {
            // this.i = 1;
            var timer = null;
            var count = 60;
            var codeText = $(".code").text();
            if (codeText == "获取验证码") {
              $(".code").css({
                color: "#ccc",
              });
              timer = setInterval(function () {
                count--;
                $(".code").text(count + "s后获取验证码");
                if (count <= 0) {
                  this.i = 0;
                  $(".code").css({
                    color: "#4461f2",
                  });
                  clearInterval(timer);
                  $(".code").text("获取验证码");
                }
              }, 1000);
            }
          }
        });
      }
      // else {
      //   return this.$message({
      //     message: "请勿重复获取验证码",
      //     type: "warning",
      //   });
      // }
    },
  },
  components: {
    // HelloWorld,
  },
};
</script>
<style lang="less" scoped>
.login-box {
  width: 630/96in;
  height: 838/96in;
  background: url("../../img/登录_slices/组 1 拷贝 3.png") no-repeat;
  position: fixed;
  top: 50%;
  transform: translateY(-49%);
  right: 1.11rem /* 111/100 */;
  text-align: center;
  padding: 108/96in 90/96in 80/96in;
  .title {
    font-size: 30/96in;
    font-weight: bold;
    color: #121212;
    height: 30/96in;
    line-height: 30/96in;
    letter-spacing: 0.1em;
  }
  input {
    width: 450/96in;
    height: 56/96in;
    box-shadow: 0 12/96in 29/96in 3/96in rgba(35, 24, 21, 0.08);
    // opacity: 0.75;
    border-radius: 5/96in;
    padding-left: 54/96in;
    margin-bottom: 18/96in;
    border: none;
    outline: none;
    font-size: 16/96in;
    font-weight: 400;
    color: #000;
    &#user {
      background: #fdfdfd url("../../img/登录_slices/我的 (1).png") 19/96in
        no-repeat;
      margin: 36/96in 0 20/96in 0;
    }
    &#phone {
      background: #fdfdfd url("../../img/登录_slices/手机.png") 19/96in
        no-repeat;
    }
    &#code {
      background: #fdfdfd url("../../img/登录_slices/验证码.png") 19/96in
        no-repeat;
    }
    &#pwd,
    &#repwd {
      background: #fdfdfd url("../../img/登录_slices/密码 .png") 19/96in
        no-repeat;
    }
    &#repwd {
      margin-bottom: 50/96in;
    }
  }
  // 记住密码
  .choose {
    display: flex;
    justify-content: flex-end;
    padding: 0 13/96in;
    color: #313339;
    font-size: 14/96in;
    margin-bottom: 20/96in;
    height: 18/96in;
    .right {
      span {
        cursor: pointer;
      }
    }
  }
  // 登录
  .login-btn {
    position: relative;
    height: 116/96in;
    // margin-bottom: 3/96in;
    cursor: pointer;
    img {
      position: absolute;
      left: -29/96in;
    }
    span {
      position: absolute;
      color: #edeef5;
      font-size: 20/96in;
      left: 50%;
      top: 36/96in;
      transform: translateX(-50%);
    }
  }
  // line
  .line {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14/96in;
    color: #313339;
    height: 14/96in;
    .line1 {
      width: 150/96in;
      height: 2/96in;
      background: #c1c1c1;
    }
    .text {
      margin: 0 27/96in;
    }
  }
  .login-wx {
    margin-top: 11/96in;
    height: 46/96in;
  }
}
.codebox {
  position: relative;
  .code {
    position: absolute;
    right: 22/96in;
    top: 19/96in;
    color: #4461f2;
    font-size: 16/96in;
    cursor: pointer;
  }
}
</style>
